import React from "react";
import "./Section.css";

interface SectionProps {
	name?: string;
	children: JSX.Element | JSX.Element[];
}

export default function Section(props: SectionProps) {

	const { name, children } = props;

	return (
		<section>
			{
				name &&
				<h4 className="sectionName">
					{
						name
					}
				</h4>
			}
			

			{
				children
			}
		</section>
	);
}
