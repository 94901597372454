// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Search_search__IhBi0 {
	max-width: 100%;
}

.Search_search__IhBi0 input {
  /*width: 100%;*/
  padding: 8px 16px;
  margin: 4pt;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: calc(12pt + 0.5vmin);
	border-radius: 8pt;
}

.Search_searchButton__9Mv4K {
	width: 30pt;
	height: 30pt;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	cursor: pointer;
	background-color: transparent;
	font-size: calc(14px + 0.5vmin);
	border-radius: 8pt;
}

@media only screen and (min-width: 700px) {
	.Search_searchButton__9Mv4K:hover {
		background-color: RoyalBlue !important;
	}
}

`, "",{"version":3,"sources":["webpack://./src/elements/search/Search.module.css"],"names":[],"mappings":";AACA;CACC,eAAe;AAChB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,sBAAsB;EACtB,sBAAsB;EACtB,+BAA+B;CAChC,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,aAAa;CACb,mBAAmB;CACnB,qBAAqB;CACrB,uBAAuB;CACvB,eAAe;CACf,6BAA6B;CAC7B,+BAA+B;CAC/B,kBAAkB;AACnB;;AAEA;CACC;EACC,sCAAsC;CACvC;AACD","sourcesContent":["\n.search {\n\tmax-width: 100%;\n}\n\n.search input {\n  /*width: 100%;*/\n  padding: 8px 16px;\n  margin: 4pt;\n  border: 1px solid #ccc;\n  box-sizing: border-box;\n  font-size: calc(12pt + 0.5vmin);\n\tborder-radius: 8pt;\n}\n\n.searchButton {\n\twidth: 30pt;\n\theight: 30pt;\n\tdisplay: flex;\n\talign-items: center;\n\talign-content: center;\n\tjustify-content: center;\n\tcursor: pointer;\n\tbackground-color: transparent;\n\tfont-size: calc(14px + 0.5vmin);\n\tborder-radius: 8pt;\n}\n\n@media only screen and (min-width: 700px) {\n\t.searchButton:hover {\n\t\tbackground-color: RoyalBlue !important;\n\t}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `Search_search__IhBi0`,
	"searchButton": `Search_searchButton__9Mv4K`
};
export default ___CSS_LOADER_EXPORT___;
