import { createContext, Dispatch, SetStateAction, useContext } from "react";


export interface LangContextType {
	lang: string;
	setLang: Dispatch<SetStateAction<string>>;
}

export const LangContext = createContext<LangContextType>(null!);

/**
 * Language context
 * @return {JSX.Element} App language context element
 */
export function useLangContext(): LangContextType {
	return useContext(LangContext);
}
