// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#settingsPage {
  display: flex;
  flex-flow: column;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  flex: 1 1 auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/SettingsPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,qBAAqB;EACrB,6BAA6B;EAC7B,cAAc;AAChB","sourcesContent":["#settingsPage {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n  align-content: center;\n  justify-content: space-around;\n  flex: 1 1 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
