import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "./pages/login/LoginPage";
import Dashboard from "./pages/dashboard/Dashboard";
import GroupsPage from "./pages/groups/GroupsPage";
import Settings from "./pages/settings/SettingsPage";
import firebase from "firebase/compat/app";
import { User } from "./entities/User";
import * as firebaseui from "firebaseui";
import { fireConfig } from "./config/firebase/FirebaseConfig";
import ScoringPage from "./pages/scoring/ScoringPage";
import Toolbar from "./elements/toolbar/Toolbar";
import { LangContext } from "./context/LangContext";
import DonesPage from "./pages/dones/DonesPage";
import getMessagingToken from "./dataLayer/getToken";
import serverSync from "./dataLayer/serverStorage";
import userStorage from "./dataLayer/userStorage";
import objects from "./utils/objects";


/**
 * Render app root element
 * @return {React.JSX.Element} App root directory
 */
function App(): React.JSX.Element {

	const [ pageTools, setPageTools ] = useState<JSX.Element[]>([]);
	const [ pageName, setPageName ] = useState<JSX.Element | null>(<div/>);
	const [ lang, setLang ] = useState<string>(localStorage.getItem("lang") || "EN");
	const [ firebaseObj, setFirebaseObj ] = useState<firebase.app.App>();
	const [ fireUi, setFireUi ] = useState<firebaseui.auth.AuthUI>();
	const [ user, setUser ] = useState<User | undefined>();
	const [ userDto, setUserDto ] = useState<firebase.User | null>(null);

	
	useEffect(() => {
		userStorage.getUser(setLocalUser, "app");
	});

	function setLocalUser(newUser: User | undefined) {
		//console.log("setLocalUser", newUser);
		if (objects.deepEqual(newUser, user)) {
			return;
		}
		setUser(newUser);
	}

	useEffect(() => {
		function initFirebase(): firebase.app.App {

			if (!firebase.apps.length) {

				const newFirebase = firebase.initializeApp(fireConfig);

				return newFirebase;
			} else {
				return firebase.app();
			}
		}

		setFirebaseObj(initFirebase());
	}, []);

	
	useEffect(() => {

		let closeSocket: () => void;

		if (user) {
			closeSocket = serverSync.init(user);
		}

		return () => {
			closeSocket?.();
		};
	}, [ user ]);

	useEffect(() => {

		const onVisibilityChange = () => {
			if (!document.hidden) {
				refreshToken();
			}
		};

		document.addEventListener("visibilitychange", onVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", onVisibilityChange);
		};
	}, [ user ]);


	useEffect(() => {
		if (firebaseObj) {
			setFireUi(firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseObj.auth()));
		}
	}, [ firebaseObj ]);


	const refreshToken = async () => {
		if (userDto) {
			try {
				userDto.getIdToken(true).then( async (accessToken) => {
					const localUser = {
						...user,
						accessToken: accessToken,
					};

					console.log("Token update ", localUser);
					// setUser(localUser);
					// localStorage.setItem("user", JSON.stringify(localUser));

					if (objects.deepEqual(localUser, user)) {
						return;
					}

					await userStorage.saveUser(localUser as User);

					if (user) {
						serverSync.init(user);
					} else {
						console.warn("no user");
					}
				}).catch((error) => {
					console.log("Error on authentication: ", error);
				});
			} catch (error) {
				console.warn("Could not refresh firebase token", error);
			}
		}
	};


	useEffect(() => {
		refreshToken();
	}, []);


	useEffect(() => {

		let unSbs: firebase.Unsubscribe;

		const initApp = () => {

			if (firebaseObj) {
				try {
					unSbs = firebaseObj.auth().onAuthStateChanged((firebaseUser: firebase.User | null) => {
						if (firebaseUser) {
						// User is signed in.
							setUserDto(firebaseUser);

							firebaseUser.getIdToken(true).then((accessToken) => {
								const localUser: User = {
									displayName: firebaseUser.displayName || "",
									email: firebaseUser.email || "",
									photoURL: firebaseUser.photoURL || "",
									id: firebaseUser.uid,
									accessToken: accessToken,
									roles: [],
								};
			
								//console.log("set user", localUser);
								// setUser(localUser);
								// localStorage.setItem("user", JSON.stringify(localUser));
			
								userStorage.saveUser(localUser);
							});

						} else {
						// User is signed out.
							console.log("User is signed out.");
							userStorage.removeUser();
						}
					}, function(error) {
						console.error(error);
					});
			
				} catch (error) {
					console.warn("Coudl not sign in", error);
				}
			}
		};
		
		initApp();

		return (() => {
			unSbs?.();
		});
	}, [ firebaseObj ]);
	

	const requestPermission = async (onFinish: () => void): Promise<void> => {

		console.log("Requesting permission...");

		onFinish();

		if (!firebaseObj) {
			console.error("Firebase not connected");
			return;
		}

		if (!user) {
			console.error("User is not defined");
			return;
		}

		if (!("Notification" in window)) {
			console.log("Notifications not supported");
			return;
		}

		await Notification.requestPermission((permission) => {
			console.log("Notification permission is: ", permission);

			if ("serviceWorker" in navigator) {
				navigator.serviceWorker.register("firebase-messaging-sw.js")
					.then((registration) => {
						console.dir(registration);
						if (user) {
							getMessagingToken(user, registration);
						}
					}).catch((err) => {
						console.error(err);
						return;
					});
			} else {
				console.log("Service Worker not supported");
			}

		});

		return;
	};

	const signOut = () => {
		console.log("SignOut");
		if (firebaseObj) {
			firebaseObj.auth().signOut().then(() => {
				// Sign-out successful.
				console.log("Signedout succesfully");
				userStorage.removeUser();
			}).catch((error) => {
				// An error happened.
				console.error("Signout error", error);
			});
		} else {
			console.log("Firebase is not initialized");
		}
		// userStorage.saveUser(undefined);
	};
	
	
	return (
		<div className="App">

			<LangContext.Provider value={{ lang, setLang }}>
				
				<Router>
		
					<Toolbar
						pageName={pageName}
						pageTools={pageTools}
					/>
						
					<div className={"appContent"}>
					
						<Routes>

							<Route
								path="/"
								element={ <Navigate to="/dashboard" /> }
							/>
								
							<Route
								path="/login"
								element={
									<LoginPage
										fireUi={fireUi}
										provider={firebase.auth.GoogleAuthProvider.PROVIDER_ID}
										signOut={signOut}
									/>
								}
							/>
								
							<Route
								path="/dashboard"
								element={
									<Dashboard
										setPageTools={setPageTools}
										setPageName={setPageName}
										requestPermission={requestPermission}
									/>
								}
							>
								<Route
									path=":taskId"
									element={
										<Dashboard
											setPageTools={setPageTools}
											setPageName={setPageName}
											requestPermission={requestPermission}
										/>
									}
								/>
							</Route>

							<Route
								path="/dones"
								element={
									<DonesPage
										setPageTools={setPageTools}
										setPageName={setPageName}
									/>
								}
							/>

							<Route
								path="/groups"
								element={
									<PrivateRoute>
										<GroupsPage
											setPageTools={setPageTools}
											setPageName={setPageName}
										/>
									</PrivateRoute>
								}
							>
								<Route
									path=":groupId"
									element={
										<PrivateRoute>
											<GroupsPage
												setPageTools={setPageTools}
												setPageName={setPageName}
											/>
										</PrivateRoute>
									}
								/>
							</Route>

							<Route
								path="/scoring"
								element={
									<PrivateRoute>
										<ScoringPage
											setPageTools={setPageTools}
											setPageName={setPageName}
										/>
									</PrivateRoute>
								}
							/>

							<Route
								path="/settings"
								element={
									<Settings
										setPageName={setPageName}
										requestPermission={requestPermission}
										signOut={signOut}
									/>
								}
							/>
								
						</Routes>
							
					</div>
				</Router>
			</LangContext.Provider>
		</div>
	);

}

export default App;

