import { DocumentData, Firestore, Query, collection, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Score } from "../../entities/Score";
import scoresStorage from "../../dataLayer/scoreStorage";


export enum IntervalEnum {
	// eslint-disable-next-line no-unused-vars
	DAY = "day",
	// eslint-disable-next-line no-unused-vars
	WEEK = "week",
	// eslint-disable-next-line no-unused-vars
	MONTH = "month"
}

const getStartTime = (interval: IntervalEnum): Date => {
	if (interval === IntervalEnum.DAY) {
		return new Date(new Date().setHours(0, 0, 0, 0));
	}

	if (interval === IntervalEnum.WEEK) {
		const date = new Date(new Date().setHours(0, 0, 0, 0));
		const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
		return new Date(date.setDate(diff));
	}

	if (interval === IntervalEnum.MONTH) {
		return new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0));
	}

	return new Date();
};

interface TotalScoreProps {
  groupId: string;
	interval: IntervalEnum;
	email?: string;
}

export default function TotalScore(props: TotalScoreProps) {
	const { groupId, interval, email } = props;
	const db: Firestore = getFirestore();
	const [ score, setScore ] = useState(0);

	useEffect(() => {

		const getScore = (groupId: string, interval: IntervalEnum, email?: string): number => {

			let result: number = 0;
			const startTime = getStartTime(interval);
			const scoresQuery: Query<DocumentData> = email ?
				query(
					collection(db, "scores"),
					where("doneDate", ">=", startTime),
					where("groupId", "==", groupId),
					where("doneAuthorEmail", "==", email),
				) : query(
					collection(db, "scores"),
					where("doneDate", ">=", startTime),
					where("groupId", "==", groupId),
				);
  
			onSnapshot(scoresQuery, (scoresSnapshot) => {
				const scores: Score[] = [];
				scoresSnapshot.forEach((doc) => {
					// console.log(score.data());
					const data = doc.data();
					const newScore: Score = scoresStorage.buildScore({
						id: data.id,
						authorEmail: data.email,
						points: data.points,
						date: data.date,
						doneId: data.doneId,
						groupId: data.groupId,
						doneAuthorEmail: data.doneAuthorEmail,
						doneDate: data.date,
						taskName: data.taskName,
					});
					scores.push(newScore);
				});

				result = getTotalScore(scores);

				setScore(result);
			});
  
			return result;
		};

		getScore(groupId, interval, email);

	}, [ db, groupId, interval ]);

	const getTotalScore = (doneScores: Score[]): number => {
		let totalScore = 0;
		if (!doneScores || doneScores.length === 0) return totalScore;
		totalScore = doneScores.map((score) => score.points).reduce((a, b) => a + b, 0);
		return totalScore;
	};

	return (
		<div>
			<p>
				{`${score} points`}
			</p>
		</div>
	);
};
