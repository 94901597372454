import { v4 as uuidv4 } from "uuid";
import { Task } from "../entities/Task";
import objects from "../utils/objects";

const type = "tasks";

const callBackCallers: Map<string, Function> = new Map();

function callAll(task: Task, echo?: boolean) {
	callBackCallers.forEach((callBackFun, callbackName) => {
		if (echo && callbackName === "backend") return;
		callBackFun(task);
	});
}

async function getTasks(
	callback: (task: Task) => void,
	callerName?: string,
) {
	if (callerName) callBackCallers.set(callerName, callback);

	const tasks: Task[] = JSON.parse(localStorage.getItem(type) || "[]");
	
	if (tasks?.length > 0) {
		tasks.forEach((item) => {
			callback(item);
		});
	}
}

async function saveTask(
	newTask: Task,
) {

	if (!newTask.lastDateModified) {
		return;
	}

	let tasks: Task[] = JSON.parse(localStorage.getItem(type) || "[]");

	let echo = false;

	const oldTask: Task | undefined = tasks.find((t) => t.id === newTask.id);

	if (oldTask) {

		if (!objects.deepEqual(oldTask, newTask)) {

			const newTaskLastDateModified = new Date(newTask.lastDateModified);
			const oldTaskLastDateModified = new Date(oldTask.lastDateModified || "");

			if (newTaskLastDateModified > oldTaskLastDateModified || !oldTask.lastDateModified) {
				tasks = tasks.map((t) => {
					return t.id === newTask.id ? newTask : t;
				});
			} else {
				echo = true;
			}
		} else {
			echo = true;
		}
	} else {
		tasks.push(newTask);
	}

	// console.log(tasks);

	localStorage.setItem(type, JSON.stringify(tasks));

	callAll(newTask, echo);
}

async function updateTask(
	taskId: string,
	args: object,
): Promise<boolean> {
	const tasks: Task[] = JSON.parse(localStorage.getItem(type) || "[]");
	const task: Task | undefined = tasks.find((item) => item.id === taskId);

	if (!task) {
		return false;
	}

	const updatedTask: Task = {
		...task,
		...args,
		lastDateModified: new Date().toISOString(),
	};
	tasks.map((item) => {
		if (item.id === taskId) {
			return updatedTask;
		} else {
			return item;
		}
	});

	localStorage.setItem(type, JSON.stringify(tasks));
	callAll(updatedTask);

	return true;
}

async function deleteTask(task: Task) {
	const tasks: Task[] = JSON.parse(localStorage.getItem(type) || "[]");
	const filteredTasks = tasks.filter((item) => item.id !== task.id);
	localStorage.setItem(type, JSON.stringify(filteredTasks));
	task.active = false;
	task.lastDateModified = new Date().toISOString();
	callAll(task);
}

function buildTask({
	...args
}) {
	const newTask: Task = {
		type: "task",
		id: uuidv4(),
		author: "",
		name: "",
		description: "",
		addedDate: new Date().toISOString(),
		group: "",
		active: false,
		recurring: false,
		reminderDate: "",
		reminderTime: "",
		lastDateModified: new Date().toISOString(),
		...args,
	};

	return newTask;
}

const tasksStorage = {
	getTasks,
	saveTask,
	buildTask,
	deleteTask,
	updateTask,
};

export default tasksStorage;
