import React, { useEffect, useState } from "react";
import { Score } from "../../entities/Score";
import "./ScoreList.css";
import ScoredDoneRow from "../scoredDoneRow/ScoredDoneRow";
import scoresStorage from "../../dataLayer/scoreStorage";
import { User } from "../../entities/User";
import userStorage from "../../dataLayer/userStorage";


export default function ScoreList() {

	const [ scores, setScores ] = useState<Score[]>([]);
	const [ user, setUser ] = useState<User>();


	useEffect(() => {
		const getUser = (user: User) => {
			setUser(user);
		};

		userStorage.getUser(getUser);
	}, []);


	const getScores = (score: Score) => {

		console.log("getScores: ", score);
		
		setScores((scores) => {
			const newScores = [ ...scores ];
			const index = newScores.findIndex((t) => t.id === score.id);

			if (index >= 0) {
				if (score.active) {
					newScores.splice(index, 1, score);
				} else {
					newScores.splice(index, 1);
				}
			} else if (score.active) {
				newScores.push(score);
			}

			return newScores;
		});
	};


	useEffect(() => {
		scoresStorage.getScores(getScores, "scoreList");
	}, [ user ]);


	return (
		<div>
			{
				scores.length > 0 ?
					<div id={"scoreList"}>
						{
							scores.map((score: Score, index: number) =>
								<ScoredDoneRow
									score={score}
									key={score.id}
								/>,
							)
						}
					</div>					:
					<div>
            No scores
					</div>
			}
		</div>
	);
}
