// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textFieldContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.textFieldContainer label {
  width: 100pt;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  margin: 4pt;
}

.textFieldContainer input, .textFieldContainer select {
  /*width: 100%;*/
  padding: 8px 16px;
  margin: 4pt;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  max-width: 600px;
  border-radius: 4px;
  font-size: calc(14pt + 0.5vmin);
}

@media only screen and ( max-width: 700px ) {
  .textFieldContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .textFieldContainer input {
    /*width: 100%;*/
    padding: 8px 16px;
    margin: 4pt;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    max-width: 100%;
    border-radius: 4px;
  }
}`, "",{"version":3,"sources":["webpack://./src/elements/textField/TextField.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,qBAAqB;EACrB,sBAAsB;EACtB,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,qBAAqB;IACrB,sBAAsB;IACtB,sBAAsB;IACtB,eAAe;IACf,kBAAkB;EACpB;AACF","sourcesContent":[".textFieldContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.textFieldContainer label {\n  width: 100pt;\n  display: flex;\n  flex-direction: row;\n  align-content: flex-start;\n  justify-content: flex-start;\n  margin: 4pt;\n}\n\n.textFieldContainer input, .textFieldContainer select {\n  /*width: 100%;*/\n  padding: 8px 16px;\n  margin: 4pt;\n  display: inline-block;\n  border: 1px solid #ccc;\n  box-sizing: border-box;\n  max-width: 600px;\n  border-radius: 4px;\n  font-size: calc(14pt + 0.5vmin);\n}\n\n@media only screen and ( max-width: 700px ) {\n  .textFieldContainer {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n\n  .textFieldContainer input {\n    /*width: 100%;*/\n    padding: 8px 16px;\n    margin: 4pt;\n    display: inline-block;\n    border: 1px solid #ccc;\n    box-sizing: border-box;\n    max-width: 100%;\n    border-radius: 4px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
