export const fireConfig = {
	apiKey: "AIzaSyCoK4qqICKN-A-EZNah8EBLkjIhBjinlYU",
	authDomain: "balancer-4b310.firebaseapp.com",
	databaseURL: "https://balancer-4b310.firebaseio.com",
	projectId: "balancer-4b310",
	storageBucket: "balancer-4b310.appspot.com",
	messagingSenderId: "658164184977",
	appId: "1:658164184977:web:ffcd4f998fe80b0a978c30",
	measurementId: "G-SBW4V9WTNX",
};
