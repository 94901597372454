import React, { useEffect, useState } from "react";
import * as firebaseui from "firebaseui";
import "firebase/compat/auth";
import "./LoginPage.css";
import "firebaseui/dist/firebaseui.css";
import useTranslate from "../../internl/translate";
import { NavLink, useLocation, Location, useNavigate } from "react-router-dom";
import Button from "../../elements/button/Button";
import { User } from "../../entities/User";
import userStorage from "../../dataLayer/userStorage";


interface LoginPageProps {
	fireUi: firebaseui.auth.AuthUI | undefined;
	provider: string;
	signOut: () => void;
}

/**
 * Render app page element
 * @param {LoginPageProps} props for login page
 * @return {JSX.Element} Login page JSX element
 */
export default function LoginPage(props: LoginPageProps) {
	
	const { fireUi, provider, signOut } = props;
	const [ user, setUser ] = useState<User>();
	const DASHBOARD = useTranslate("dashboard");
	const YOU_ARE_SIGNED = useTranslate("You are signed in as");
	const SIGNED_OUT = useTranslate("Sign out");
	const navigate = useNavigate();
	const location: Location = useLocation();

	// @ts-ignore: Unreachable code error
	const from = location.state?.from?.pathname || "/";

	useEffect(() => {
		const getUser = (user: User) => {
			setUser(user);
		};

		userStorage.getUser(getUser, "dashboard");
	}, []);

	useEffect(() => {
		if (user) {
			navigate(from);
		}
	}, [ user, from, navigate ]);
	
	useEffect(() => {
		
		if (fireUi) {
			
			fireUi.start("#firebaseui-auth-container", {
				signInFlow: "popup",
				signInSuccessUrl: from,
				signInOptions: [
					{
						provider: provider,
						scopes: [
							"https://www.googleapis.com/auth/contacts.readonly",
						],
					},
				],
			});
		}
	}, [ fireUi, from, provider ]);
	
	
	const onSignOut = async () => {
		signOut();
	};
	
	
	return (
		<div className={"page"} id={"loginPage"}>
			
			<div id={"loginPageContent"}>
				
				<div id="firebaseui-auth-container" style={{ display: user ? "none" : "block" }}/>
				
				{
					user &&
					<NavLink to={"/"}>
						<Button
							styles={{ margin: 8 }}
							text={DASHBOARD}
							onButtonClick={async () => {}}
						/>
					</NavLink>
				}
				
				{
					user &&
						<div>
							{
								YOU_ARE_SIGNED.concat(" ").concat(user?.displayName)
							}
						</div>
				}
				
				{
					user &&
						<Button
							onButtonClick={onSignOut}
							styles={{ margin: 8 }}
							text={SIGNED_OUT}
						/>
				}
				
			</div>
			
		</div>
	);

}
