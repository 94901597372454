import React from "react";
import styles from "./ConfirmationDialog.module.css";
import Dialog from "../../elements/dialog/Dialog";
import ReactDOM from "react-dom/client";
import { LangContext } from "../../context/LangContext";
import Button from "../../elements/button/Button";


interface ConfirmationDialogProps {
	onConfirm: (onFinish: () => void, target?: any) => Promise<void>;
	onClose: () => void;
	target?: any;
	text?: string;
}

export default function showConfirmationDialog(props: ConfirmationDialogProps): () => void {

	const { target, onClose, onConfirm, text = "Please confirm" } = props;
	const lang = localStorage.getItem("lang") || "EN";
	const setLang = () => {};

	const rootElement = document.getElementById("root");
	const container = document.createElement("div");
	container.classList.add("dialog-container");
	
	document.body.appendChild(container);

	const removeDialog = () => {
		if (document.body.contains(container)) {
			document.body.removeChild(container);
		}
	};

	const onDialogClose = async () => {
		if (onClose) onClose();
		removeDialog();
	};

	const confirm = async (onFinish: () => void, parentTarget: any) => {
		console.log("Confirm");
		await onConfirm(onFinish, parentTarget);
		// if (onClose) onClose();
		onDialogClose();
	};
	
	if (rootElement) {
		// TODO: Check if is another way to append element to root
		ReactDOM.createRoot(container as Element).render(
			<LangContext.Provider value={{ lang, setLang }}>
				<Dialog
					closeDialog={onDialogClose}
					tools={[
						<Button
							key={"confirm button"}
							text={"Confirm"}
							classes="confirmButton"
							onButtonClick={confirm}
							target={target}
							confirmButton
						/>,
					]}
					style={styles}
				>
					<h3 className={styles.dialogMessage}>
						{
							text
						}
					</h3>
				</Dialog>
			</LangContext.Provider>,
		);
	}

	return removeDialog;
}
