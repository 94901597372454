import "./Spinner.css";
import React from "react";


export default function Spinner() {

	return (
		<div className="spinner">
			<div className="loader" />
		</div>
	);

}
