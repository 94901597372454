import React from "react";
import "./Toast.css";
import ReactDOM from "react-dom/client";


export default function notify(message: string = "Toast", interval: number = 3): void {
	
	const rootElement = document.getElementById("root");
	const container = document.createElement("div");
	container.classList.add("toast-container");
	
	document.body.appendChild(container);
	
	if (rootElement) {
		// TODO: Check if is another way to append element to root
		ReactDOM.createRoot(container as Element).render(
			<Toast
				message={message}
			/>,
		);
	}
	
	// Auto remove the toast container
	const removeToast = () => {
		document.body.removeChild(container);
	};
	setTimeout(removeToast, interval * 1000);
}


interface ToastProps {
	message: string;
}


const Toast = (props: ToastProps) => {
	
	const { message } = props;
	
	return (
		<div className="notification-container">
			<div>
				<p className="notification-title">
					{ message }
				</p>
			</div>
		</div>
	);
};
