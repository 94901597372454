// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html {
  min-height: 100vh;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  box-sizing: border-box;
}

:root {
  --color: 31, 48, 162;
  --color-light: 96, 113, 227;
  --color-dark: 4, 17, 103;
  --bg-color: #ffffff;
  --bluegrey: #546e7a;
  --bluegrey-light: #94c5db;
  --bluegrey-rgb: 84, 110, 122;
  --task-background: rgb(65, 105, 225);
  --alpha: 0.7;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;;AAGA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,oBAAoB;EACpB,2BAA2B;EAC3B,wBAAwB;EACxB,mBAAmB;EACnB,mBAAmB;EACnB,yBAAyB;EACzB,4BAA4B;EAC5B,oCAAoC;EACpC,YAAY;AACd","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n\nhtml {\n  min-height: 100vh;\n  box-sizing: border-box;\n}\n\nbody {\n  min-height: 100vh;\n}\n\n#root {\n  min-height: 100vh;\n  box-sizing: border-box;\n}\n\n:root {\n  --color: 31, 48, 162;\n  --color-light: 96, 113, 227;\n  --color-dark: 4, 17, 103;\n  --bg-color: #ffffff;\n  --bluegrey: #546e7a;\n  --bluegrey-light: #94c5db;\n  --bluegrey-rgb: 84, 110, 122;\n  --task-background: rgb(65, 105, 225);\n  --alpha: 0.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
