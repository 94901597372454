import React, { forwardRef } from "react";
import "./Dialog.css";
import Button from "../button/Button";
import useTranslate from "../../internl/translate";


export interface DialogProps {
	children: any;
	closeDialog: (onFinish: () => void) => Promise<void>;
	tools?: any;
	name?: string;
	style?: React.CSSProperties;
}

export default forwardRef(function Dialog(props: DialogProps, ref: React.ForwardedRef<HTMLDivElement>) {
	
	const { tools, closeDialog, children, name, style } = props;
	const CLOSE = useTranslate("close");
	
	return (
		<div className="dialog" style={style} ref={ref}>
			
			<div className="dialogContainer">

				{
					name &&
					<h3 className="dialogName">
						{
							name
						}
					</h3>
				}
				

				<div className="dialogBody">
					{
						children
					}
				</div>
				
				<div className="dialogToolbar">
					{
						tools
					}

					<Button
						text={CLOSE}
						onButtonClick={closeDialog}
					/>

				</div>

			</div>
			
		</div>
	);
});
