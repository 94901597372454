import React, { useState } from "react";
import Button from "../button/Button";
import styles from "./Search.module.css";

export type SearchProps = {
	onClick: (onFinish: () => void) => Promise<any>;
	onSearch: (value: string) => void;
	active?: boolean;
}

export default function Search(props: SearchProps) {

	const { onClick, onSearch, active } = props;
	const [ status, setStatus ] = useState<boolean>(active || false);
	const [ value, setValue ] = useState<string>("");

	const onButtonClick = async (onFinish: () => void): Promise<any> => {
		setStatus(true);
		onClick(onFinish);
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		onSearch(e.target.value);
	};

	
	return (
		<div className={styles.search}>
			{
				!status ?
					<Button
						key="searchButton"
						text={
							<i className="material-icons">search</i>
						}
						onButtonClick={onButtonClick}
						classes={styles.searchButton}
						noShadow
					/> :
					<input
						value={value}
						onChange={onChange}
						autoFocus
					/>
			}
			
		</div>
	);
}
