import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import userStorage from "./dataLayer/userStorage";


/**
 * Render app root element
 * @return {JSX.Element} App root directory
 */
export default function PrivateRoute({ children }: { children: JSX.Element }): JSX.Element {

	const location = useLocation();
	const user = userStorage.getUser();

	// console.log(auth.user);

	if (!user) {
		return (
			<Navigate
				to="/login"
				state={{ from: location }} replace
			/>
		);
	}

	return children;
}
