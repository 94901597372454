// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.groupsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

table {
  width: 600px;
  border-collapse: collapse;
  border-spacing: 30px;
  border-radius: 8px;
}

thead {
  background-color:black;
  color: white;
}

thead th {
  padding: 16px;
}

tr {
  padding: 4px;
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: #D6EEEE;
}

tbody > tr:hover {
  background-color: #2fe2e2;
}

.invitedGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/groups/GroupsPage.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,qBAAqB;EACrB,uBAAuB;AACzB","sourcesContent":["\n.groupsContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  align-content: center;\n}\n\ntable {\n  width: 600px;\n  border-collapse: collapse;\n  border-spacing: 30px;\n  border-radius: 8px;\n}\n\nthead {\n  background-color:black;\n  color: white;\n}\n\nthead th {\n  padding: 16px;\n}\n\ntr {\n  padding: 4px;\n  cursor: pointer;\n}\n\ntr:nth-child(even) {\n  background-color: #D6EEEE;\n}\n\ntbody > tr:hover {\n  background-color: #2fe2e2;\n}\n\n.invitedGroup {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
