// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#languageDropdown {
    position: relative;
    /*margin-right: 20%;*/
    transition: 0.6s ease;
}

.languageDropdownContent span {
    z-index: -1;
    color: white;
    padding: 12px 12px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    background-color: rgba(var(--color), .7);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    transition: 0.6s ease;
}

#languageButton {
    font-weight: bold;
    font-size: 14px;
    height: 45px;
    width: 45px;
    transition: 0.6s ease;
    margin: 0;
}

.languageDropdownContent {
    z-index: 6;
    position: absolute;
    min-width: 30px;
    overflow: auto;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 3;
    margin: 0 0 0 8px;
    border-radius: 8px;
    align-items: center;
    align-content: center;
    text-align: center;
    transition: 0.6s ease;
    font-size: 14px;
    font-weight: bold;
}

.languageDropdownContent span:hover {
    background-color: rgba(var(--color-dark), 0.8);
}

.selected {
    background-color: rgba(var(--color-dark), 0.8) !important;
}

@media screen and (max-width: 800px) {

    #languageDropdown {
        margin-right: 0;
    }
}


`, "",{"version":3,"sources":["webpack://./src/elements/language_button/LanguageButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;IACd,eAAe;IACf,wCAAwC;IACxC,kCAA0B;YAA1B,0BAA0B;IAC1B,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,WAAW;IACX,qBAAqB;IACrB,SAAS;AACb;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,wCAAwC;IACxC,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,8CAA8C;AAClD;;AAEA;IACI,yDAAyD;AAC7D;;AAEA;;IAEI;QACI,eAAe;IACnB;AACJ","sourcesContent":["#languageDropdown {\n    position: relative;\n    /*margin-right: 20%;*/\n    transition: 0.6s ease;\n}\n\n.languageDropdownContent span {\n    z-index: -1;\n    color: white;\n    padding: 12px 12px;\n    text-decoration: none;\n    display: block;\n    cursor: pointer;\n    background-color: rgba(var(--color), .7);\n    backdrop-filter: blur(2px);\n    transition: 0.6s ease;\n}\n\n#languageButton {\n    font-weight: bold;\n    font-size: 14px;\n    height: 45px;\n    width: 45px;\n    transition: 0.6s ease;\n    margin: 0;\n}\n\n.languageDropdownContent {\n    z-index: 6;\n    position: absolute;\n    min-width: 30px;\n    overflow: auto;\n    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);\n    z-index: 3;\n    margin: 0 0 0 8px;\n    border-radius: 8px;\n    align-items: center;\n    align-content: center;\n    text-align: center;\n    transition: 0.6s ease;\n    font-size: 14px;\n    font-weight: bold;\n}\n\n.languageDropdownContent span:hover {\n    background-color: rgba(var(--color-dark), 0.8);\n}\n\n.selected {\n    background-color: rgba(var(--color-dark), 0.8) !important;\n}\n\n@media screen and (max-width: 800px) {\n\n    #languageDropdown {\n        margin-right: 0;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
