import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./Toolbar.css";
import LanguageButton from "../language_button/LanguageButton";


interface ToolbarProps {
	pageName: JSX.Element | null;
	pageTools: JSX.Element[];
}

export default function Toolbar(props: ToolbarProps) {
	const { pageName, pageTools } = props;
	const [ openMenu, setOpenMenu ] = useState<boolean>(false);


	function handleClick(e: MouseEvent) {
		// console.dir(e);
		// @ts-ignore
		if (e?.target?.id !== "menuIcon") {
			setOpenMenu(false);
		}

	}

	useEffect(() => {

		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	}, []);
	
	// TODO: Add online status
	return (
		<div id="topNav">

			<div id="toolbar">

				<div className={"leftToolsContainer"}>
					<div className="menuDropdown">
						<button
							className="btn"
							id="menuButton"
							onClick={() => setOpenMenu(!openMenu)}
						>
							<i className="material-icons" id="menuIcon">
								menu
							</i>
						</button>
						{
							openMenu &&
							<div className="menuDropdownContent">
								<Link to={"/"}>
									Dashboard
								</Link>
								<Link to={"/dones"}>
									Dones
								</Link>
								<Link to={"/scoring"}>
									Scoring
								</Link>
								<Link to={"/groups"}>
									Groups
								</Link>
								<Link to={"/settings"}>
									Settings
								</Link>
							</div>
						}
					</div>

					<LanguageButton
						id="toolbarLanguageDropdown"
					/>
				</div>


				{
					pageName
				}

				<div className={"leftToolsContainer"}>
					{
						pageTools
					}
				</div>

			</div>
		</div>
	);
}
