import React, { useEffect, useState } from "react";
import { Group } from "../../entities/Group";
import { Done } from "../../entities/Done";
import "./ScoringPage.css";
import Button from "../../elements/button/Button";
import { Score } from "../../entities/Score";
import ScoreSelector from "../../elements/score/ScoreSelector";
import ScoreList from "../../modules/scoreList/ScoreList";
import donesStorage from "../../dataLayer/donesStorage";
import scoresStorage from "../../dataLayer/scoreStorage";
import { User } from "../../entities/User";
import groupsStorage from "../../dataLayer/groupsStorage";
import userStorage from "../../dataLayer/userStorage";

interface UsersMap {
	[key: string]: string;
}

interface ScoringProps {
	// eslint-disable-next-line no-unused-vars
	setPageTools: (elements: JSX.Element[]) => void;
	// eslint-disable-next-line no-unused-vars
	setPageName: (element: JSX.Element) => void;
}

export default function ScoringPage(props: ScoringProps) {
	
	const { setPageName, setPageTools } = props;
	const [ dones, setDones ] = useState<Done[]>([]);
	const [ users, setUsers ] = useState<UsersMap>({});
	const [ openItemId, setOpenItemId ] = useState<string>("");
	const [ selectedScore, setSelectedScore ] = useState<number>(0);
	const [ groupList, setGroupList ] = useState<Group[]>([]);
	const [ user, setUser ] = useState<User>();


	useEffect(() => {
		const getUser = (user: User) => {
			setUser(user);
		};

		userStorage.getUser(getUser);
	}, []);
	
	useEffect(() => {
		
		setPageName(
			<h3 className={"pageName"}>
				Scoring
			</h3>,
		);
		
		return () => {
			setPageName(<div/>);
		};
	}, [ setPageName ]);

	useEffect(() => {

		setPageTools([ <div key="123"/> ]);

		return () => {
			setPageTools([ <div key="123"/> ]);
		};
	
	}, [ setPageTools ]);

	const getGroups = (group: Group) => {

		if (!group || !user) {
			return;
		}

		console.log(group);
		console.log(user?.email);

		setGroupList((groups) => {
			const newGroups = [ ...groups ];
			const index = newGroups.findIndex((g) => g.id === group.id);

			if (index >= 0) {
				if (group.participants.includes(user?.email) && !(group.name === "personal")) {
					newGroups.splice(index, 1, group);
				} else {
					newGroups.splice(index, 1);
				}
			} else if (group.participants.includes(user?.email)) {
				newGroups.push(group);
			}

			return newGroups;
		});

	};


	useEffect(() => {
		groupsStorage.getGroups(getGroups, "groups_page");
	}, [ user ]);


	useEffect(() => {

		const getNicknames = (emails: string[]) => {

			console.debug(emails);

			/* const functions = getFunctions();
			const getUserNicknames = httpsCallable(functions, "getUserNicknames");

			if (getUserNicknames) {
				getUserNicknames({
					emails: emails,
				}).then((result) => {
					setUsers(result.data as UsersMap);
				}).catch((error) => {
					// Getting the Error details.
					const code = error.code;
					console.error(code + error.message);
					console.error(error.details);
				});
			
			} */
		};

		const emails: string[] = [];

		groupList.forEach((group: Group) => {
			group.participants?.forEach((email: string) => {
				if (!emails.includes(email)) {
					emails.push(email);
				}
			});
		});

		if (emails.length > 0) {
			getNicknames(emails);
		}

	}, []);


	const getDone = (done: Done | null) => {

		//console.log("getDone: ", done);

		if (!done || !user) return;

		setDones((dones) => {
			const newDones = [ ...dones ];
			const index = newDones.findIndex((t) => t.id === done.id);

			if (index >= 0) {
				if (done.active && done.arbiters?.includes(user?.email)) {
					newDones.splice(index, 1, done);
				} else {
					newDones.splice(index, 1);
				}
			} else if (done.active && done.arbiters?.includes(user?.email)) {
				newDones.push(done);
			}

			newDones.sort((a: Done, b: Done) => {
				if (!a.date || !b.date) return 1;
				const aDate = new Date(a.date);
				const bDate = new Date(b.date);
				return aDate < bDate ? 1 : -1;
			});
			
			return newDones;
		});
	};

	useEffect(() => {
		donesStorage.getDones({
			callback: getDone,
			caller: "scorePage",
		});
	}, [ user ]);


	function onStarClick(done: Done, score: number) {
		console.debug(done.id, score);
		setOpenItemId(done.id ?? "");
		setSelectedScore(score);
	}

	function onItemClick(itemId: string) {
		console.debug(itemId);
		setSelectedScore(0);
		setOpenItemId(itemId);
	}

	async function scoreDone(onFinish: any, target?: any) {

		const done: Done = target as Done;
		if (!done?.id) {
			setTimeout(onFinish(), 1000);
			return;
		}

		const score: Score = scoresStorage.buildScore({
			date: new Date().toISOString(),
			authorEmail: user?.email || "",
			points: selectedScore,
			doneId: done.id,
			groupId: done.group,
			doneAuthorEmail: done.author,
			doneDate: done.date,
			taskName: done.taskName || "",
		});

		await scoresStorage.saveScore(score);

		const doneArbiters = done.arbiters?.filter((email) => email !== user?.email);

		donesStorage.updateDone(done.id, {
			arbiters: doneArbiters,
		});

		onFinish();
	}

	async function deleteDone(onFinish: any, target?: any) {

		const done: Done = target as Done;

		if (!done?.id) {
			setTimeout(onFinish(), 1000);
			return;
		}

		done.arbiters = done.arbiters?.filter((email) => email != user?.email);

		donesStorage.updateDone(done.id, done);
	}
	
	
	return (
		<div className={"page"}>

			<h3>
				To add score:
			</h3>
			
			<div>
				{ dones.length > 0 ?
					dones.map((done: Done) =>
						<div
							className={openItemId == done.id ? "scoreItem openItem" : "scoreItem"}
							key={done.id}
						>
							<div
								className={"doneName"}
								onClick={() => onItemClick(done.id ?? "")}
							>
								<p>
									{done.taskName}
								</p>
							</div>
							<div
								className={"scoreSeparator"}
								onClick={() => onItemClick(done.id ?? "")}
							>
								<p>
										done by
								</p>
							</div>
							<div
								className={"doneAuthor"}
								onClick={() => onItemClick(done.id ?? "")}
							>
								<p>
									{users?.[done.author] ?? done.author}
								</p>
							</div>
							<div
								className={"scoreDoneDate"}
								onClick={() => onItemClick(done.id ?? "")}
							>
								<p>
									{
										done.date && new Date(done.date).toLocaleString()
									}
								</p>
							</div>

							<ScoreSelector
								classes="scoreStars"
								onClick={onStarClick}
								target={done}
								selectedScore={openItemId == done.id ? selectedScore : 0}
							/>

							{
								selectedScore > 0 &&
									<Button
										classes={"saveButton"}
										text="Save"
										onButtonClick={scoreDone}
										target={done}
									/>
							}
								
							<Button
								classes="deleteButton"
								text="Delete"
								onButtonClick={deleteDone}
								target={done}
							/>
								
						</div>
					)	:
					<p>
						Nothing done yet
					</p>
				}
			</div>
			
			<h3>
				Scored:
			</h3>
			<ScoreList />

		</div>
	);
}
