// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#loginPage {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
}

#loginPageContent {
    width: 100%;
    flex-grow: 1;
    /*width: 100%;*/
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/LoginPage.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,uBAAuB;IACvB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":["\n#loginPage {\n    width: 100%;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    justify-content: center;\n    flex: 1 1 auto;\n}\n\n#loginPageContent {\n    width: 100%;\n    flex-grow: 1;\n    /*width: 100%;*/\n    display: flex;\n    flex-flow: column;\n    justify-content: center;\n    flex-wrap: wrap;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
