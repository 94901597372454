// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmationDialog_dialogMessage__QZnr1 {
  text-align: center;
  margin: 4pt;
}`, "",{"version":3,"sources":["webpack://./src/modules/confirmationDialog/ConfirmationDialog.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".dialogMessage {\n  text-align: center;\n  margin: 4pt;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogMessage": `ConfirmationDialog_dialogMessage__QZnr1`
};
export default ___CSS_LOADER_EXPORT___;
