export const texts = new Map<string, Map<string, string>>([
	[ "dashboard",
		new Map<string, string>([
			[ "EN", "Dashboard" ],
			[ "RO", "Dashboard" ],
			[ "RU", "Dashboard" ],
		]),
	],
	[ "You are signed in as",
		new Map<string, string>([
			[ "EN", "You are signed in as" ],
			[ "RO", "You are signed in as" ],
			[ "RU", "You are signed in as" ],
		]),
	],
	[ "Sign out",
		new Map<string, string>([
			[ "EN", "Sign out" ],
			[ "RO", "Delogare" ],
			[ "RU", "Выйти" ],
		]),
	],
	[ "to do",
		new Map<string, string>([
			[ "EN", "To do:" ],
			[ "RO", "De facut:" ],
			[ "RU", "Задачи:" ],
		]),
	],
	[ "save",
		new Map<string, string>([
			[ "EN", "Save" ],
			[ "RO", "Salveaza" ],
			[ "RU", "Соханить" ],
		]),
	],
	[ "invite",
		new Map<string, string>([
			[ "EN", "Invite" ],
			[ "RO", "Invita" ],
			[ "RU", "Пригласить" ],
		]),
	],
	[ "create",
		new Map<string, string>([
			[ "EN", "Create" ],
			[ "RO", "Creaza" ],
			[ "RU", "Создать" ],
		]),
	],
	[ "into group",
		new Map<string, string>([
			[ "EN", "in group" ],
			[ "RO", "in grupa" ],
			[ "RU", "в групе" ],
		]),
	],
	[ "task edit",
		new Map<string, string>([
			[ "EN", "Edit task" ],
			[ "RO", "Editare sarcinei:" ],
			[ "RU", "Задача:" ],
		]),
	],
	[ "task name",
		new Map<string, string>([
			[ "EN", "Task name:" ],
			[ "RO", "Numele:" ],
			[ "RU", "Имя:" ],
		]),
	],
	[ "personal",
		new Map<string, string>([
			[ "EN", "Personal" ],
			[ "RO", "Personal" ],
			[ "RU", "Личные" ],
		]),
	],
	[ "enter task name",
		new Map<string, string>([
			[ "EN", "Enter task name" ],
			[ "RO", "Introduceți numele sarcinei" ],
			[ "RU", "Введите имя задачи" ],
		]),
	],
	[ "create new group",
		new Map<string, string>([
			[ "EN", "Create new group" ],
			[ "RO", "Grupă nouă" ],
			[ "RU", "Создать новую групу" ],
		]),
	],
	[ "close",
		new Map<string, string>([
			[ "EN", "Close" ],
			[ "RO", "Închide" ],
			[ "RU", "Закрыть" ],
		]),
	],
]);
