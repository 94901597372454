import React, { useEffect } from "react";
import DonesList from "../../modules/donesList/DonesList";


interface DonesPageProps {
	setPageTools: any;
	setPageName: any;
	// db: Firestore | undefined;
}


export default function DonesPage(props: DonesPageProps) {
	
	const { setPageName } = props;

	useEffect(() => {

		setPageName(
			<h3 className={"pageName"}>
				Dones
			</h3>,
		);

		return () => {
			setPageName(<div/>);
		};
	
	}, [ setPageName ]);


	return (
		<div className={"page"}>

			<DonesList />
			
		</div>
	);

}
