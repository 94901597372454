import React, { useEffect, useState } from "react";
import { Done } from "../../entities/Done";
import { Task } from "../../entities/Task";
import Spinner from "../spinner/Spinner";
import styles from "./Button.module.css";
import { Group } from "../../entities/Group";
import { Score } from "../../entities/Score";
import showConfirmationDialog from "../../modules/confirmationDialog/ConfirmationDialog";


interface ButtonProps {
	id?: string;
	text: any;
	// shows loading spinner and removes it when called onFinish function, also can return the target object
	onButtonClick: (onFinish: () => void, target?: any) => Promise<any>;
	target?: Task | Done | Group | Score | string;
	styles?: React.CSSProperties;
	classes?: string;
	type?: "button" | "submit" | "reset";
	active?: boolean;
	withConfirmationDialog?: boolean;
	confirmButton?: boolean;
	redButton?: boolean;
	noShadow?: boolean;
}

export default function Button(props: ButtonProps) {

	const { id, text, styles: customStyles, onButtonClick: onButtonClick, target,
		classes, type = "button", active = true, withConfirmationDialog,
		confirmButton, redButton, noShadow } = props;

	const [ loading, setLoading ] = useState<boolean>(false);
	// const [ localState ] = useState<string>(state || "active");
	const [ localActive, setLocalActive ] = useState(active);

	useEffect(() => {
		setLocalActive(!loading);
	}, [ loading ]);

	const onFinish = () => {
		setLoading(false);
	};

	const onClick = async () => {
		if (localActive) {
			setLoading(true);

			if (withConfirmationDialog) {
				showConfirmationDialog({
					onConfirm: onButtonClick,
					onClose: onFinish,
					target: target,
				});
			} else {
				await onButtonClick(onFinish, target);
			}
		}
	};

	const localStyles: React.CSSProperties = {
		...styles,
		...customStyles,
	};

	if (!localActive) {
		localStyles["backgroundColor"] = "lightgrey";
	}

	return (
		<button
			id={id}
			className={
				[ styles.button, classes, "button",
					confirmButton ? styles.confirmButton : "",
					redButton ? styles.redButton : "",
					!noShadow ? styles.withShadow : "" ].join(" ")
			}
			style={localStyles}
			onClick={() => onClick()}
			type={type}
		>
			{
				loading ? <Spinner /> : text
			}
		</button>
	);

}
