import React, { useEffect, useState } from "react";
import { Task } from "../../entities/Task";
import useTranslate from "../../internl/translate";
import Button from "../../elements/button/Button";
import { calculatePriority } from "../../utils/priority";

export type TaskCardProps = {
	task: Task;
	openTaskDialog: (task: Task) => void;
	getColor: (priority: number) => string;
	doneTask: (onFinish: () => void, target?: Task) => Promise<any>;
	updatePriorityMap: (taskId: string, prior: number) => void;
}

export default function TaskCard(props: TaskCardProps) {

	const { task, openTaskDialog, getColor, doneTask, updatePriorityMap } = props;
	const INTO_GROUP = useTranslate("into group");
	const [ priority, setPriority ] = useState<number | undefined>(task.priority);

	useEffect(() => {
		if (!priority) calculatePriority(task, updatePriority);
	}, []);

	const updatePriority = (prior: number) => {
		task.id && updatePriorityMap (task.id, prior);
		setPriority(prior);
	};

	return (
		<div
			className={"taskItem"}
			key={task.id}
		>
			<div
				className={"taskPriority taskRow"}
				onClick={() => openTaskDialog(task)}
				style={{ backgroundColor: getColor(priority || 1) }}
			>
				<p>
					{priority}
				</p>
			</div>
			<div
				className={"taskName taskRow"}
				onClick={() => openTaskDialog(task)}
				style={{ backgroundColor: getColor(priority || 1) }}
			>
				<p>
					{task.name}
				</p>
			</div>
			<div
				className={"separator taskRow"}
				onClick={() => openTaskDialog(task)}
				style={{ backgroundColor: getColor(priority || 1) }}
			>
				<p>
					{INTO_GROUP}
				</p>
			</div>
			<div
				className={"taskGroup taskRow"}
				onClick={() => openTaskDialog(task)}
				style={{ backgroundColor: getColor(priority || 1) }}
			>
				<p>
					{task.groupName}
				</p>
			</div>

			<Button
				classes={"doneTaskButton taskRow confirmButton"}
				text="Done"
				onButtonClick={doneTask}
				target={task}
				withConfirmationDialog
				styles={{
					backgroundColor: "rgb(19, 196, 19)",
					borderTopRightRadius: "8pt",
					borderBottomRightRadius: "8pt",
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					margin: 0,
					width: "100%",
					height: "100%",
				}}
			/>
										
		</div>
	);
}