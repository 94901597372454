import React from "react";
import { Done } from "../../entities/Done";
import "./ScoreSelector.css";


const stars: number[] = [ 1, 2, 3, 4, 5 ];

interface ScoreSelectorProps {
    onClick: (target: Done, score: number) => void;
    styles?: React.CSSProperties;
	classes?: string;
	target: Done;
	selectedScore: number;
}

export default function ScoreSelector(props: ScoreSelectorProps) {

	const { styles, onClick, classes, target, selectedScore } = props;

	function onStarClick(score: number) {
		onClick(target, score);
	}

	return (
		<div
			style={styles}
			className={[ classes, "scoreContainer" ].join(" ") }
		>
			{
				stars.map((star) =>
					<div
						className="star"
						key={"star"+star}
						onClick={() => onStarClick(star)}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							height="24px"
							viewBox="0 0 24 24"
							width="24px"
							fill= { star <= selectedScore ? "#000000" : "#ffffff" }
						>
							<path
								stroke="#000000"
								strokeWidth="2"
								d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
							/>
						</svg>
					</div>,
				)
			}
		</div>
	);
}
