import React, { useEffect, useState } from "react";
import GroupDialog from "../../modules/groupDialog/GroupDialog";
import { Group } from "../../entities/Group";
import Button from "../../elements/button/Button";
import { useNavigate, useParams } from "react-router-dom";
import "./GroupsPage.css";
import TotalScore, { IntervalEnum } from "../../elements/totalScore/TotalScore";
import groupsStorage from "../../dataLayer/groupsStorage";
import invitedGroupsStorage from "../../dataLayer/invitedGroupsStorage";
import { v4 as uuidv4 } from "uuid";
import userStorage from "../../dataLayer/userStorage";
import { User } from "../../entities/User";


interface DashboardProps {
	setPageTools: (elements: React.ReactElement[]) => void;
	setPageName: (element: React.ReactElement) => void;
}

export default function GroupsPage(props: DashboardProps) {
	
	const { setPageTools, setPageName } = props;
	const [ user, setUser ] = useState<User>();
	const [ groupList, setGroupList ] = useState<Group[]>([]);
	const [ group, setGroup ] = useState<Group>();
	const [ invitedList, setInvitedList ] = useState<Group[]>([]);
	const { groupId } = useParams();
	const navigate = useNavigate();


	useEffect(() => {
		const getUser = (user: User) => {
			setUser(user);
		};

		userStorage.getUser(getUser);
	}, []);


	useEffect(() => {
		if (groupId) {

			const existingGroup = groupList.find((listGroup: Group) => listGroup.id === groupId);
			console.log(existingGroup);

			if (existingGroup) {
				setGroup(existingGroup);
			} else {
				setGroup(groupsStorage.buildGroup({
					id: groupId,
				}));
			}
		} else {
			setGroup(undefined);
		}
	}, [ groupId, groupList ]);
	
	useEffect(() => {
		setPageName(
			<h3 className={"pageName"}>
				Groups
			</h3>,
		);
		
		return () => {
			setPageName(<div/>);
		};
	}, [ setPageName ]);


	const getGroups = (group: Group) => {

		console.log(group);

		setGroupList((groups) => {
			const newGroups = [ ...groups ];
			const index = newGroups.findIndex((g) => g.id === group.id);

			if (index >= 0) {
				if (group.active && !(group.name === "personal")) {
					newGroups.splice(index, 1, group);
				} else {
					newGroups.splice(index, 1);
				}
			} else if (group.active) {
				newGroups.push(group);
			}

			return newGroups;
		});
	};


	useEffect(() => {
		groupsStorage.getGroups(getGroups, "groups_page");
	}, []);


	const getInvitedGroups = (group: Group) => {

		setInvitedList((invitedList) => {
			const newGroups = [ ...invitedList ];
			const index = newGroups.findIndex((t) => t.id === group.id);

			if (index >= 0) {
				if (group.active && !(group.name === "personal")) {
					newGroups.splice(index, 1, group);
				} else {
					newGroups.splice(index, 1);
				}
			} else if (group.active) {
				newGroups.push(group);
			}
			
			return newGroups;
		});
	};

	useEffect(() => {
		invitedGroupsStorage.getGroups(getInvitedGroups);
	}, []);
	

	useEffect(() => {
		
		setPageTools([
			<Button
				key={"addButton"}
				classes="toolButton"
				onButtonClick={openNewGroupDialog}
				text={
					<div className="addButton">
						<p>
							Add
						</p>
						<i className="material-icons">add</i>
					</div>
				}
				target={groupsStorage.buildGroup({})}
			/>,
		]);

		return () => {
			setPageTools([ <div key="123"/> ]);
		};
		
	}, [ setPageTools ]);
	
	
	const openGroupDialog = async (groupId: string) => {
		// console.dir(task);
		navigate(groupId);
	};

	const openNewGroupDialog = async (onFinish: () => void) => {
		// console.dir(task);
		openGroupDialog(uuidv4());

		onFinish();
	};

	const acceptInvitation = async (onFinish: () => void, groupId: any) => {
		/* const acceptInvitation = httpsCallable(functions, "acceptInvitation");
		if (acceptInvitation) {
			acceptInvitation({ group: groupId }).then(() => {
				onFinish();
			});
		} */
	};

	const refuseInvitation = async (onFinish: () => void, groupId: any) => {
		/* const refuseInvitation = httpsCallable(functions, "refuseInvitation");
		if (refuseInvitation) {
			refuseInvitation({ group: groupId }).then(() => {
				onFinish();
			});
		} */
	};
	
	console.log(invitedList);
	
	return (
		<div className={"page"}>

			<div style={{ padding: 16 }}>
				<h3>
					Participating to:
				</h3>

				<div className="groupsContainer">
					<table className="groupsTable">
						<thead className="theadGroups">
							<tr>
								<th>Group</th>
								<th>My day score</th>
								<th>My week score</th>
								<th>My month score</th>
								<th>Group week total</th>
							</tr>
						</thead>
						<tbody>
							{
								groupList.map((group: Group) =>
									<tr
										key={group.id}
										onClick={() => {
											openGroupDialog(group.id);
										}}
									>
										<td>
											{group.name}
										</td>
										<td>
											<TotalScore
												groupId={group.id}
												interval={IntervalEnum.DAY}
												email={user?.email}
											/>
										</td>
										<td>
											<TotalScore
												groupId={group.id}
												interval={IntervalEnum.WEEK}
												email={user?.email}
											/>
										</td>
										<td>
											<TotalScore
												groupId={group.id}
												interval={IntervalEnum.MONTH}
												email={user?.email}
											/>
										</td>
										<td>
											<TotalScore
												groupId={group.id}
												interval={IntervalEnum.WEEK}
											/>
										</td>
									</tr>,
								)
							}
						</tbody>
					</table>
				</div>
			</div>

			<div style={{ padding: 16 }}>
				<h3>
					Invited to:
				</h3>
				
				<div>
					{
						invitedList.map((group: Group) =>
							<div className={"invitedGroup"} key={group.id}>
								{/* TODO: Align verticaly */}
								<p>
									{group.name}
								</p>
								<Button
									text="Accept"
									onButtonClick={acceptInvitation}
									classes="confirmButton"
									target={group.id}
								/>
								<Button
									text={"Refuse"}
									onButtonClick={refuseInvitation}
									classes="cancelButton"
									target={group.id}
								/>
							</div>,
						)
					}
				</div>
			</div>

			{
				group && (
					<GroupDialog
						group={group}
						setGroup={setGroup}
					/>
				)
			}
		</div>
	);
}
