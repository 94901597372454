import React from "react";
import "./TextField.css";

export interface TextFieldProps {
	label: string;
	value: any;
	placeHolder?: string;
	required?: boolean;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function TextField(props: TextFieldProps) {
	const { label, value, placeHolder, required, onChange } = props;

	return (
		<div className="textFieldContainer">
			<label htmlFor="taskName">
				<b>
					{ label }
				</b>
			</label>
			<input
				type="text"
				placeholder={placeHolder}
				name="taskName"
				value={value}
				onChange={onChange}
				required={required}
			/>
		</div>
	);
}
