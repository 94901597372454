// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* Style the horizontal ruler */
/*hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
}*/

.toolBar {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

/* The Close Button (x) */
.close {
    /*position: absolute;*/
    /*right: 2%;*/
    /*top: 2%;*/
    /*font-size: 40px;*/
    /*font-weight: bold;*/
    color: #3e0404;
    background-color: Transparent;
    margin: 0;
    padding: 0;
    height: 24px;
    width: 24px;
}

.close:hover, .close:focus {
    color: #f44336;
    cursor: pointer;
}

.interval input {
    width: 50pt;
    text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/modules/taskDialog/TaskDialog.css"],"names":[],"mappings":";;AAEA,+BAA+B;AAC/B;;;EAGE;;AAEF;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,8BAA8B;AAClC;;AAEA,yBAAyB;AACzB;IACI,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;IACd,6BAA6B;IAC7B,SAAS;IACT,UAAU;IACV,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB","sourcesContent":["\n\n/* Style the horizontal ruler */\n/*hr {\n    border: 1px solid #f1f1f1;\n    margin-bottom: 25px;\n}*/\n\n.toolBar {\n    width: 100%;\n    margin-top: 16px;\n    display: flex;\n    justify-content: space-between;\n}\n\n/* The Close Button (x) */\n.close {\n    /*position: absolute;*/\n    /*right: 2%;*/\n    /*top: 2%;*/\n    /*font-size: 40px;*/\n    /*font-weight: bold;*/\n    color: #3e0404;\n    background-color: Transparent;\n    margin: 0;\n    padding: 0;\n    height: 24px;\n    width: 24px;\n}\n\n.close:hover, .close:focus {\n    color: #f44336;\n    cursor: pointer;\n}\n\n.interval input {\n    width: 50pt;\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
