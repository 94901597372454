import donesStorage from "../dataLayer/donesStorage";
import { Task } from "../entities/Task";
import { Done } from "../entities/Done";


async function calculatePriority(task: Task, setPriority: (priority: number) => void): Promise<void> {

	if (task.name === "buy opener") {
		console.log("calculate priority", task);
	}

	let dones: Done[] = [];

	const getDone = (done: Done | null) => {

		if (task.name === "buy opener") {
			console.log("buy opener");
		}

		/* if (!done) {ß
			setPriority(1);
			return;
		} */

		const newDones = [ ...dones ];
		let priority = 1;
		const index = newDones.findIndex((t) => t.id === done?.id);

		if (index >= 0) {
			if (done?.active) {
				newDones.splice(index, 1, done);
			} else {
				newDones.splice(index, 1);
			}
		} else if (done?.active) {
			newDones.push(done);
		}

		newDones.sort((a: Done, b: Done) => {
			if (!a.date || !b.date) return 1;
			const aDate = new Date(a.date);
			const bDate = new Date(b.date);
			return aDate < bDate ? 1 : -1;
		});

		dones = [ ...newDones ];

		if (task.recurring) {

			const done = newDones.filter((done) => done.task === task.id)[0];
	
			let lastDoneDate = done?.date;

			if (!lastDoneDate) {
				lastDoneDate = task.addedDate || new Date().toISOString();
			}

			const currentDate: Date = new Date(Date.now());
			const doneDate: Date = new Date(lastDoneDate);
			const diffTime = Math.abs(currentDate.getTime() - doneDate.getTime());
			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

			//console.log(task.name, diffDays);

			priority = diffDays - (task.interval || 0);

			//console.log(task.name, priority);

			if (priority > 0) {
				priority = Math.round(diffDays / (task.interval || 1)) * 10;
			}

		} else {
			if (task.reminderDate) {
				const currentDate: Date = new Date(Date.now());
				const reminderDate: Date = new Date(task.reminderDate);
				const diffTime = reminderDate.getTime() - currentDate.getTime();
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	
				priority = 0 - diffDays;
			} else if (task.addedDate) {
				const currentDate: Date = new Date(Date.now());
				const addedDate: Date = new Date(task.addedDate);
				const diffTime = Math.abs(currentDate.getTime() - addedDate.getTime());
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	
				priority = diffDays;
			}
		}

		setPriority(priority);
	};


	donesStorage.getDones({
		callback: getDone,
		caller: "priority"+task.id,
		taskId: task.id,
	});

}


export {
	calculatePriority,
};

