import { getMessaging, getToken } from "firebase/messaging";
import { User } from "../entities/User";
import { MessageToken } from "../entities/MessageToken";
import serverStorage from "../dataLayer/serverStorage";


export default async function getMessagingToken(
	user: User,
	serviceWorkerRegistration: ServiceWorkerRegistration,
): Promise<string> {

	console.log("getMessagingToken");

	// TODO: Save device token to backend
	// Get registration token. Initially this makes a network call, once retrieved
	// subsequent calls to getToken will return from cache.
	const messaging = getMessaging();
	const token = getToken(messaging, {
		serviceWorkerRegistration,
		vapidKey: "BJyy8jYu75Ptn6gGA25dz3lO3sKk8FXPj692oeznPjPu7-OMq_n0YsKSmqevZjoREhQUtZkUXrFYa_4ombApr2E",
	}).then(async (tokenString) => {
		console.log(tokenString);
		if (tokenString) {
			// Send the token to your server and update the UI if necessary

			const tokenDto: MessageToken = {
				id: tokenString,
				lastDateModified: new Date(),
				email: user.email,
			};

			serverStorage.sendMToken(tokenDto);

			return tokenString;
		} else {
			// Show permission request UI
			console.log("No registration token available. Request permission to generate one.");
			
			return "";
		}
	}).catch((err) => {
		console.error("An error occurred while retrieving token. ", err);

		return "";
	});

	return await token;
}
