// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scoreContainer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 4pt;
    cursor: pointer;
}

.star {
    max-width: 24px;
    padding: 0 4px;
}

.star:hover > svg {
    fill: black;
}

.star:has(~ .star:hover) > svg {
    fill: black;
}`, "",{"version":3,"sources":["webpack://./src/elements/score/ScoreSelector.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf","sourcesContent":[".scoreContainer {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n    box-sizing: border-box;\n    padding: 4pt;\n    cursor: pointer;\n}\n\n.star {\n    max-width: 24px;\n    padding: 0 4px;\n}\n\n.star:hover > svg {\n    fill: black;\n}\n\n.star:has(~ .star:hover) > svg {\n    fill: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
