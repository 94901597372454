import React, { useEffect, useState } from "react";
import { useLangContext } from "../../context/LangContext";
import Button from "../button/Button";
import "./LanguageButton.css";


const languages: string[] = [ "EN", "RO", "RU" ];

interface LangButtonProps {
	id?: string;
	styles?: React.CSSProperties;
}

/**
 * Render Language button and it content
 * @param {LangButtonProps} props contains language and set language
 * @return {JSX.Element} Button element and all it's functionality
 */
export default function LanguageButton(props: LangButtonProps): JSX.Element {
	const { styles: customStyles, id } = props;
	const { lang, setLang } = useLangContext();
	const [ openLanguageSelect, setOpenLanguageSelect ] = useState<boolean>(false);

	useEffect(() => {
		// listen for clicks outside the elemnet and close the menu
		document.addEventListener("click", handleClick);
		
		/* return () => {
			document.removeEventListener("click", handleClick);
		}; */
	}, []);
	
	/**
	 * check what is clicked and close menus
	 * @param {MouseEvent} mouseEvent browser event object
	*/
	function handleClick(mouseEvent: MouseEvent) {
		// @ts-ignore
		if (mouseEvent?.target?.id !== "languageButton") {
			setOpenLanguageSelect(false);
		}
	}

	const onLanguageSelect = (language: string) => {
		setLang(language);
		setOpenLanguageSelect(false);
		
		localStorage.setItem("lang", language);
	};

	const toggleMenu = async (onFinish: any): Promise<void> => {
		onFinish();
		setOpenLanguageSelect(!openLanguageSelect);
	};

	return (
		<div className="languageDropdown" id={id}>

			<Button
				id={"languageButton"}
				text={lang}
				classes="toolButton"
				onButtonClick={toggleMenu}
				styles={{
					backgroundColor: "transparent",
					...customStyles,
				}}
				noShadow
			/>
			
			{
				openLanguageSelect &&
					<div className="languageDropdownContent">
						{
							languages.map((language: string) => {
								return (
									<span
										key={language}
										onClick={() => onLanguageSelect(language)}
										className={lang === language ? "selected" : ""}
									>
										{
											language
										}
									</span>
								);
							})
						}
					</div>
			}
		
		</div>
	);
}
