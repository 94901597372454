// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.notification-container {
    background-color: rgba(18, 54, 162, 0.5);
    box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box;
    border-radius: 8px;
    position: fixed;
    top: 60pt;
    right: 20pt;
    z-index: 10;
    transition: transform 1s ease-in-out;
    animation: toast-in-right 1s;
    pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/elements/toast/Toast.css"],"names":[],"mappings":";;AAEA;IACI,wCAAwC;IACxC,uCAAuC;IACvC,kCAA0B;YAA1B,0BAA0B;IAC1B,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,SAAS;IACT,WAAW;IACX,WAAW;IACX,oCAAoC;IACpC,4BAA4B;IAC5B,oBAAoB;AACxB","sourcesContent":["\n\n.notification-container {\n    background-color: rgba(18, 54, 162, 0.5);\n    box-shadow: 0 8px 8px 0 rgba(0,0,0,0.2);\n    backdrop-filter: blur(5px);\n    color: white;\n    padding-left: 20px;\n    padding-right: 20px;\n    font-size: 16px;\n    font-weight: bold;\n    box-sizing: border-box;\n    border-radius: 8px;\n    position: fixed;\n    top: 60pt;\n    right: 20pt;\n    z-index: 10;\n    transition: transform 1s ease-in-out;\n    animation: toast-in-right 1s;\n    pointer-events: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
