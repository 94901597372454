// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 4px;
	box-sizing: border-box;
	box-shadow: none;
}

.loader {
	height: 90%;
	aspect-ratio: 1 / 1;
	position: relative;
	border: 5px solid #f3f3f3;
	border-radius: 50%;
	border-top: 5px solid #3498db; /* Safari */
	animation: spin 2s linear infinite;
	box-sizing: border-box;
}

/* Safari */

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}`, "",{"version":3,"sources":["webpack://./src/elements/spinner/Spinner.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,WAAW;CACX,aAAa;CACb,uBAAuB;CACvB,YAAY;CACZ,sBAAsB;CACtB,gBAAgB;AACjB;;AAEA;CACC,WAAW;CACX,mBAAmB;CACnB,kBAAkB;CAClB,yBAAyB;CACzB,kBAAkB;CAClB,6BAA6B,EACe,WAAW;CACvD,kCAAkC;CAClC,sBAAsB;AACvB;;AAEA,WAAW;;AAMX;CACC,KAAK,uBAAuB,EAAE;CAC9B,OAAO,yBAAyB,EAAE;AACnC","sourcesContent":[".spinner {\n\theight: 100%;\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\tpadding: 4px;\n\tbox-sizing: border-box;\n\tbox-shadow: none;\n}\n\n.loader {\n\theight: 90%;\n\taspect-ratio: 1 / 1;\n\tposition: relative;\n\tborder: 5px solid #f3f3f3;\n\tborder-radius: 50%;\n\tborder-top: 5px solid #3498db;\n\t-webkit-animation: spin 2s linear infinite; /* Safari */\n\tanimation: spin 2s linear infinite;\n\tbox-sizing: border-box;\n}\n\n/* Safari */\n@-webkit-keyframes spin {\n\t0% { -webkit-transform: rotate(0deg); }\n\t100% { -webkit-transform: rotate(360deg); }\n}\n\n@keyframes spin {\n\t0% { transform: rotate(0deg); }\n\t100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
