import { User } from "../entities/User";
import objects from "../utils/objects";

const type = "user";

const callBackCallers: Map<string, Function> = new Map();

function callAll(user: User | undefined) {
	callBackCallers.forEach((callBack) => {
		callBack(user);
	});
}

function getNickname(): string | null {
	return localStorage.getItem("nickname");
}

function setNickname(name: string): void {
	localStorage.setItem("nickname", name);
}

async function saveUser(user: User | undefined) {
	
	/* if (!user) {
		localStorage.removeItem(type);
		callAll(undefined);
		return;
	} */
	
	let localUser;

	const userString = localStorage.getItem(type);

	if (userString) {
		try {
			localUser = JSON.parse(userString);
		} catch (error) {
			console.warn("Could not parse user", userString);
		}
	}

	const fullUser = {
		...localUser,
		...user,
	};

	if (!objects.deepEqual(fullUser, localUser)) {
		localStorage.setItem(type, JSON.stringify(fullUser));
		callAll(fullUser);
	}
}


async function removeUser() {
	localStorage.removeItem(type);
	callAll(undefined);
}


async function getUser(
	callback?: (user: User) => void,
	caller?: string,
): Promise<User | undefined> {
	if (caller && callback) callBackCallers.set(caller, callback);
	
	const userString = localStorage.getItem(type);

	if (userString) {
		try {
			const user: User = JSON.parse(userString);
			if (callback) {
				callback(user);
			}
			return user;
		} catch (error) {
			console.warn(error);
			return undefined;
		}
	} else {
		console.warn("No saved user");
		return undefined;
	}
}

const userStorage = {
	getNickname,
	setNickname,
	saveUser,
	getUser,
	removeUser,
};

export default userStorage;
