// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Set a style for all buttons */
.Button_button__-Wz48 {
	height: 25pt;
	max-width: 200pt;
	min-width: 20pt;
	padding: 0 8pt;
	color: white;
	border: none;
	cursor: pointer;
	border-radius: 4px;
	background-color: rgb(var(--color));
	transition: 0.6s ease;
	align-content: center;
	align-items: center;
	margin: 4pt;
	font-size: calc(11pt + 1vmin);
	overflow: hidden;
}

.Button_withShadow__U0vKb {
	box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
}

.Button_confirmButton__FWJQc {
	background-color: #4CAF50;
}

.Button_redButton__fnER2 {
	background-color: #c33155;
}

.Button_button__-Wz48:hover {
	box-shadow: 0 8px 8px 0 rgba(0,0,0,0.4);
}

.Button_button__-Wz48:hover > div:not(.Button_dialog__LOUrC) {
	box-shadow: 0 8px 8px 0 rgba(0,0,0,0.4);
}
  `, "",{"version":3,"sources":["webpack://./src/elements/button/Button.module.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;CACC,YAAY;CACZ,gBAAgB;CAChB,eAAe;CACf,cAAc;CACd,YAAY;CACZ,YAAY;CACZ,eAAe;CACf,kBAAkB;CAClB,mCAAmC;CACnC,qBAAqB;CACrB,qBAAqB;CACrB,mBAAmB;CACnB,WAAW;CACX,6BAA6B;CAC7B,gBAAgB;AACjB;;AAEA;CACC,uCAAuC;AACxC;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,uCAAuC;AACxC;;AAEA;CACC,uCAAuC;AACxC","sourcesContent":["/* Set a style for all buttons */\n.button {\n\theight: 25pt;\n\tmax-width: 200pt;\n\tmin-width: 20pt;\n\tpadding: 0 8pt;\n\tcolor: white;\n\tborder: none;\n\tcursor: pointer;\n\tborder-radius: 4px;\n\tbackground-color: rgb(var(--color));\n\ttransition: 0.6s ease;\n\talign-content: center;\n\talign-items: center;\n\tmargin: 4pt;\n\tfont-size: calc(11pt + 1vmin);\n\toverflow: hidden;\n}\n\n.withShadow {\n\tbox-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);\n}\n\n.confirmButton {\n\tbackground-color: #4CAF50;\n}\n\n.redButton {\n\tbackground-color: #c33155;\n}\n\n.button:hover {\n\tbox-shadow: 0 8px 8px 0 rgba(0,0,0,0.4);\n}\n\n.button:hover > div:not(.dialog) {\n\tbox-shadow: 0 8px 8px 0 rgba(0,0,0,0.4);\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__-Wz48`,
	"withShadow": `Button_withShadow__U0vKb`,
	"confirmButton": `Button_confirmButton__FWJQc`,
	"redButton": `Button_redButton__fnER2`,
	"dialog": `Button_dialog__LOUrC`
};
export default ___CSS_LOADER_EXPORT___;
