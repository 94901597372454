import React, { useEffect, useState } from "react";
import { Group } from "../../entities/Group";
import Button from "../../elements/button/Button";
import useTranslate from "../../internl/translate";
import Dialog from "../../elements/dialog/Dialog";
import Section from "../../elements/section/Section";
import TotalScore, { IntervalEnum } from "../../elements/totalScore/TotalScore";
import { useNavigate } from "react-router-dom";
import groupsStorage from "../../dataLayer/groupsStorage";
import serverStorage from "../../dataLayer/serverStorage";
import userStorage from "../../dataLayer/userStorage";
import { User } from "../../entities/User";


interface GroupsPageProps {
	setGroup: (group: Group | undefined) => void;
	group: Group | undefined;
}

export default function GroupDialog(props: GroupsPageProps) {
	
	const { setGroup, group } = props;
	const [ user, setUser ] = useState<User>();
	const SAVE = useTranslate("save");
	const INVITE = useTranslate("invite");
	const navigate = useNavigate();
	const [ newParticipantEmail, setNewParticipantEmail ] = useState<string>("");


	useEffect(() => {
		const getUser = (user: User) => {
			setUser(user);
		};

		userStorage.getUser(getUser);
	}, []);
	

	async function saveGroup(onFinish: () => void) {

		if (group) {

			if (user?.email) {
				group.participants = [ user?.email ];
			}

			groupsStorage.saveGroup(group).then(() => {
				onFinish();
			});
		}
	}
	
	async function deleteGroup(onFinish: () => void, target: any) {

		groupsStorage.deleteGroup(target, user?.email);

		closeDialog(onFinish);
	}
	
	async function addParticipant(onFinish?: () => void) {

		const matchParticipants: string[] |undefined = group?.participants.filter((participant: string) =>
			participant === newParticipantEmail,
		);

		if (matchParticipants?.length === 0 && group?.id) {
			serverStorage.inviteUser(newParticipantEmail, group?.id).then(() => {
				if (onFinish) onFinish();
			});
		}

		setNewParticipantEmail("");
	}

	function onEnter(event: React.KeyboardEvent<HTMLInputElement>) {
		event.preventDefault();

		if (event.key === "Enter") {
			if (newParticipantEmail) {
				addParticipant();
			}
			setNewParticipantEmail("");
		}
	}

	async function closeDialog(onFinish: () => void) {
		setGroup(undefined);
		navigate("/groups");
		onFinish();
	}

	function updateGroup(attributeName: string, value: string | number | boolean) {
		console.dir(value);

		const newGroup: Group = {
			...groupsStorage.buildGroup({ ...group }),
			[attributeName]: value,
		};
		
		setGroup(newGroup);
	}
	
	
	return (
		<Dialog
			closeDialog={closeDialog}
			name={"Edit group"}
			tools={[
				<Button
					key={"saveButton"}
					text={SAVE}
					classes="confirmButton"
					confirmButton
					onButtonClick={saveGroup}
				/>,
			]}
		>

			<Section>
				<div className="textField">
					<label htmlFor="groupName">
						Group name:
					</label>
					<input
						type="text"
						placeholder="Enter group name"
						name="groupName"
						required
						value={group?.name}
						onChange={(e) => updateGroup("name", e.target.value)}
					/>
				</div>
			</Section>

			{
				group?.active &&
					<Section name={"Participants:"}>
						<table>
							<thead>
								<tr>
									<th>
										Email
									</th>
									<th>
										Day score
									</th>
									<th>
										Week score
									</th>
									<th>
										Month score
									</th>
								</tr>
							</thead>
							<tbody>
								{
									group.participants.map((participant) => (
										<tr key={participant}>
											<td>
												<p>
													{ participant === user?.email ? "me" : participant }
												</p>
											</td>
											<td>
												<TotalScore
													groupId={group.id}
													interval={IntervalEnum.DAY}
													email={participant}
												/>
											</td>
											<td>
												<TotalScore
													groupId={group.id}
													interval={IntervalEnum.WEEK}
													email={participant}
												/>
											</td>
											<td>
												<TotalScore
													groupId={group.id}
													interval={IntervalEnum.MONTH}
													email={participant}
												/>
											</td>
										</tr>
									))
								}
							</tbody>
						</table>
					</Section>
			}
			{
				group?.active &&
						<Section name={"Invited users:"}>
							<div>
								{
									group?.invited.length !== 0 ? (
										group?.invited.map((participant) => (
											<div key={participant}>
												{ participant }
											</div>
										))
									) :
										<p>
											No invetations
										</p>
								}
							</div>
						</Section>
			}
			{
				group?.active &&
						<Section name={"Invite user:"}>
							<div className="textField">

								<input
									type="email"
									placeholder="Enter participant's email"
									name="addParticipant"
									value={newParticipantEmail}
									onChange={(e) => setNewParticipantEmail(e.target.value)}
									onKeyUp={(e) => onEnter(e)}
								/>
								
								<Button
									text={INVITE}
									classes="confirmButton"
									onButtonClick={addParticipant}
								/>
							</div>
						</Section>
			}

			{
				group?.active &&
					<Section>
						<div>
							<Button
								classes="redButton"
								redButton
								text={"Delete group"}
								onButtonClick={deleteGroup}
								target={group}
								styles={{ width: 200 }}
							/>
						</div>
					</Section>
			}
			
		</Dialog>
	);
}
