// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scoredDoneItem {
  transition: all 1s linear;
  box-sizing: border-box;
  max-width: 1000px;
}

.scoredDoneItem > .scoredDoneRow {
  display: grid;
  border-radius: 8pt;
  align-content: center;
  box-sizing: border-box;
  grid-template-columns: 25fr 2fr 25fr 13fr 35fr;
}

.scoredDoneItem .deleteButton {
  display: none;
}

.scoredDoneItem > header {
  display: none;
}


.openScoreItem {
  /* height: 70pt; */ /* Full height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  transition: all 1s linear;
  background-color: gainsboro;
  border-radius: 8pt;
}

.openScoreItem > header {
  margin: 16px 8px 8px 8px;
  display: flex;
  flex-direction: row-reverse;
}

.openScoreItem > .scoredDoneRow > .actionRow {
  grid-row: 2;
  grid-column: 5;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.openScoreItem > .scoredDoneRow > .actionRow > .deleteButton {
  display: block;
  background-color: #f44336;
}`, "",{"version":3,"sources":["webpack://./src/modules/scoredDoneRow/ScoredDoneRow.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,8CAA8C;AAChD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;;AAGA;EACE,kBAAkB,EAAE,gBAAgB;EACpC,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,oBAAoB;EACpB,yBAAyB;EACzB,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B","sourcesContent":[".scoredDoneItem {\n  transition: all 1s linear;\n  box-sizing: border-box;\n  max-width: 1000px;\n}\n\n.scoredDoneItem > .scoredDoneRow {\n  display: grid;\n  border-radius: 8pt;\n  align-content: center;\n  box-sizing: border-box;\n  grid-template-columns: 25fr 2fr 25fr 13fr 35fr;\n}\n\n.scoredDoneItem .deleteButton {\n  display: none;\n}\n\n.scoredDoneItem > header {\n  display: none;\n}\n\n\n.openScoreItem {\n  /* height: 70pt; */ /* Full height */\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: stretch;\n  transition: all 1s linear;\n  background-color: gainsboro;\n  border-radius: 8pt;\n}\n\n.openScoreItem > header {\n  margin: 16px 8px 8px 8px;\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.openScoreItem > .scoredDoneRow > .actionRow {\n  grid-row: 2;\n  grid-column: 5;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.openScoreItem > .scoredDoneRow > .actionRow > .deleteButton {\n  display: block;\n  background-color: #f44336;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
