import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";

const container = document.getElementById("root");
ReactDOM.createRoot(container as Element).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

/* serviceWorker.register({
	onSuccess: (registration: ServiceWorkerRegistration) => {
		console.log(registration);
		if (user) {
			getMessagingToken(user, registration);
		}
	},
	onUpdate: (registration: ServiceWorkerRegistration) => {
		console.log(registration);
	},
}); */
