// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.sectionName {
  margin-top: 8pt;
  margin-bottom: 4pt;
}`, "",{"version":3,"sources":["webpack://./src/elements/section/Section.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["section {\n  padding: 8px;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.sectionName {\n  margin-top: 8pt;\n  margin-bottom: 4pt;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
