// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(136, 153, 191, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  align-content: flex-start;
  padding: 4pt;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.dialogContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 16px;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  overflow-y: auto;
}

.dialogBody {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.dialogToolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 16pt;
}

.dialogName {
  margin-top: 0;
  margin-bottom: 4pt;
}`, "",{"version":3,"sources":["webpack://./src/elements/dialog/Dialog.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,OAAO;EACP,MAAM;EACN,WAAW,EAAE,eAAe;EAC5B,YAAY,EAAE,gBAAgB;EAC9B,cAAc,EAAE,4BAA4B;EAC5C,0CAA0C;EAC1C,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,sBAAsB;EACtB,kCAA0B;UAA1B,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,0CAA0C;EAC1C,aAAa;EACb,kBAAkB;EAClB,mCAA2B;UAA3B,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".dialog {\n  position: fixed;\n  z-index: 1;\n  left: 0;\n  top: 0;\n  width: 100%; /* Full width */\n  height: 100%; /* Full height */\n  overflow: auto; /* Enable scroll if needed */\n  background-color: rgba(136, 153, 191, 0.8);\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  align-items: center;\n  align-content: flex-start;\n  padding: 4pt;\n  box-sizing: border-box;\n  backdrop-filter: blur(2px);\n}\n\n.dialogContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n  background-color: rgba(255, 255, 255, 0.5);\n  padding: 16px;\n  border-radius: 8px;\n  backdrop-filter: blur(10px);\n  overflow-y: auto;\n}\n\n.dialogBody {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.dialogToolbar {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  padding-top: 16pt;\n}\n\n.dialogName {\n  margin-top: 0;\n  margin-bottom: 4pt;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
